<template>
    <div>
        <slot v-if="loading">
            <div class="tgju-widget-content full-padding">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </slot>
        <div class="market-profile-mobile-pages" v-if="!loading && globalCryptoExchangesProfile">
            <div class="market-symbol-row">
                <div class="market-symbol-row-right back-profile-page">
                    <router-link class="mobile-page-header-icon pointer back-icon" :to="{ name: 'GlobalExchanges'}">
                        <i class="header-uil-icon uil uil-angle-right-b"></i>
                    </router-link>
                    <div class="market-symbol-avatar" :style="avatarFinder"></div>
                    <div class="market-symbol-info">
                        <h1 class="market-symbol-title">{{globalCryptoExchangesProfile.profile.category_title}}</h1>
                        <span class="market-symbol-title-en">
                            تعداد ارز : 
                            <span class="market-symbol-market">{{globalCryptoExchangesProfile.items.length}}</span>
                        </span>
                    </div>
                </div>
                <div class="market-symbol-row-left">
                    <div class="market-symbol-info">
                        <!-- <div class="market-symbol-price">
                            <span class="price" data-col="info.last_trade.PDrCotVal">3</span>
                        </div>
                        <span class="market-symbol-change">
                            <span class="change-tag" data-col="info.last_trade.last_change_percentage">
                                <span class="market_header_summary.yesterday_dt.plain">4</span>
                            </span>
                        </span> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="market-profile-inner"  v-if="!loading && globalCryptoExchangesProfile">
            <ul>
                <li class="li-head">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">امکانات</div>
                        <div class="profile-inner-table-value"></div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">معاملات روزانه</div>
                        <div class="profile-inner-table-value"> {{ globalCryptoExchangesProfile.profile.data ? globalCryptoExchangesProfile.profile.data['volume 24h'] : '-' }}</div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">سرمایه در گردش</div>
                        <div class="profile-inner-table-value"> {{ globalCryptoExchangesProfile.profile.data ? globalCryptoExchangesProfile.profile.data['liquidity'] : '-' }}</div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">سهم بازار</div>
                        <div class="profile-inner-table-value"> {{ globalCryptoExchangesProfile.profile.data ? globalCryptoExchangesProfile.profile.data['vol share'] : '-' }}</div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">تعداد بازار</div>
                        <div class="profile-inner-table-value"> {{ globalCryptoExchangesProfile.profile.data ? globalCryptoExchangesProfile.profile.data['markets'] : '-' }}</div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">کشور</div>
                        <div class="profile-inner-table-value"> {{ globalCryptoExchangesProfile.profile.data ? globalCryptoExchangesProfile.profile.data['country'] : '-' }}</div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">نوع</div>
                        <div class="profile-inner-table-value"> {{ globalCryptoExchangesProfile.profile.data ? globalCryptoExchangesProfile.profile.data['centralized'] : '-' }}</div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">وب‌سایت</div>
                        <div class="profile-inner-table-value"> {{ globalCryptoExchangesProfile.profile.data ? globalCryptoExchangesProfile.profile.data['website'] : '-' }}</div>
                    </div>
                </li>
                <li>
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">کارمزدها</div>
                        <div class="profile-inner-table-value"> {{ globalCryptoExchangesProfile.profile.data ? globalCryptoExchangesProfile.profile.data['fees'] : '-' }}</div>
                    </div>
                </li>
                <li style="border: 0 !important;">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">ای پی آی</div>
                        <div class="profile-inner-table-value"> {{ globalCryptoExchangesProfile.profile.data ? globalCryptoExchangesProfile.profile.data['api'] : '-' }}</div>
                    </div>
                </li>
                <li class="li-head">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">ارزها</div>
                        <div class="profile-inner-table-value"></div>
                    </div>
                </li>
            </ul>
        </div>
        <slot v-if="!loading">
            <div v-for="(item, index) in globalCryptoExchangesProfile.items" :key="index" class="market-main-item">
                <!-- <router-link class="market-main-item-row r-low "  :to="{ name: 'MarketProfile', params: { name: item.id },}"> -->
                   
                <div class="market-main-item-row r-low ">
                    <div class="market-main-item-col-logo">
                        <img :src="'https://static.tgju.org/images/crypto/assets/'+ item.market+'.png'" :alt="item.category_title">
                    </div>
                    <div class="market-main-item-col item-col-right">
                        <div class="market-main-item-title">
                            <h2>{{ findCryptoName(item) }}</h2>
                        </div>
                        <div class="market-main-item-time"><i class="uil uil-clock"></i><span>{{ to_yyyy_mm_dd(item.updated_at) }}</span></div>
                    </div>
                    <div class="market-main-item-col item-col-left">
                        <div class="market-main-item-price"><span>{{ formatPrice(item.p) }}</span><div class="item-price-badge">دلار</div></div>
                        <div class="market-main-item-change"> {{ item.pair }} </div>
                    </div>
                </div>
            </div>
        </slot>
    </div>
</template>
<script>
// import NewsComments from '@/views/TgjuPostsMobile.vue'
import moment from "moment-jalaali";

export default {
    name: 'globalCryptoExchangesProfileView',
    component: {
        // NewsComments,
    },
    data: function () {
        return {
            globalCryptoExchangesProfile: {},
            cryptoName : {},
            loading:false,
            supplier : {
                    "chat" : "چت",
                    "phone" : "تلفن",
                    "email" : "ایمیل",
                    "ticket" : "تیکت",
                    "android" : "اندرويد",
                    "exchange" : "صرافی",
                    "platform" : "بازار مبادلاتی",
                    "irt" : "تومان",
            },
        }
    },
    mounted() {
        // دریافت اطلاعات تحلیل های تایید شده ی عمومی
        this.loadData();
    },
    computed: {
        // آواتار یک شاخص را پیدا می کند
        avatarFinder: function () {
            if (this.globalCryptoExchangesProfile.profile.supplier.image) {
                return  {
                    'position' : 'relative',
                    'background-image': 'url(https://static.tgju.org/views/default/images/exchanges-logo/' + this.globalCryptoExchangesProfile.profile.supplier.slug + '.png)',
                }
            } 
            return  {
                'position' : 'relative',
                'background-image': 'url(https://static.tgju.org/images/no-icon.png)',
            }
        },
    },
    methods: {
        formatPrice(value) {
            if (value === null) {
                return '0';
            }

            value = parseFloat(value);
            return parseFloat(value.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        to_yyyy_mm_dd(date) {
            return moment(date).format("H:m:s - jYYYY/jMM/jDD");
        },
        findCryptoName(item) {
            var result = item.market.replace('-sell','').replace('-buy','');
            return this.cryptoName[result] ? this.cryptoName[result].fa : item.title;
        },
        finder(str) {
            if (str == null) {
                return '';
            }
            for (const [key, value] of Object.entries(this.supplier)) {
                str = str.replace(key, " " + value);
            }
            return str;
        },
        loadData() {
            this.loading = true;
            this.$helpers.makeRequest('POST', '/exchanges/global-crypto-profile', {
                slug: this.$route.params.slug,
                id: this.$route.params.id,
            }).then(api_response => {

                if ( api_response.status == 200 ) {
                    this.globalCryptoExchangesProfile = api_response.data.response.detail;
                    this.cryptoName = api_response.data.response.detail.crypto_name;
                    this.loading = false;
                }
            });
        },
        // دکمه بازگشت
        back() {
            this.$router.back()
        },
    }
}
</script>